<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Transferts</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="transfers"
          placeholder-text-filter="Chercher un transfert"
          :display-text-filter="true"
          :display-status-filter="true"
          :list-status-filter="getStatusList"
          :display-reset="true"
          @changePage="changePage"
        />

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="transfers"
              no-top-border
              :show-actions="false"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              hover
              :link="(item) => `/transfers/${item.id}`"
              :empty-img-src="require('@/assets/images/icons/transfer.svg')"
              empty-message="Vous n'avez aucun transfert"
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination state="transfers"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :range="2"
                          :display-per-page="true"
                          @change="changePage" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import orderStatus from '@/mixins/orderStatus'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import pagination from '@/mixins/pagination'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [userData, formatsDate, formatsCurrency, orderStatus, pagination],
  computed: {
    getStatusList () {
      return [
        {
          value: 'pending',
          label: 'En attente de la complétion de la commande'
        },
        {
          value: 'scheduled',
          label: 'En cours de paiement'
        },
        {
          value: 'processed',
          label: 'Paiement envoyé'
        }
      ]
    },
    cols () {
      return [
        {
          header: 'Date',
          property: 'payedAt',
          headerClass: 'col-date',
          method: (item) => {
            return item.payedAt ? this.$longDate(item.payedAt) : ''
          },
          isSortable: true
        },
        {
          header: 'Numéro de transfert',
          property: 'transactionId',
          isSortable: true
        },
        {
          header: 'Commande',
          property: 'order.reference',
          method: (item) => {
            return get(item, 'merchantOrder.order.reference', '')
          },
          isSortable: true
        },
        {
          header: 'Commission',
          property: 'commissionAmount',
          method: (item) => {
            return this.$money(item.commissionAmount)
          }
        },
        {
          header: 'Montant payé',
          property: 'payableAmount',
          method: (item) => {
            return this.$money(item.payableAmount)
          },
          isSortable: true
        },
        {
          header: 'Statut',
          property: 'status',
          method: (item) => {
            return this.getTransferStatusText(item.status)
          },
          isSortable: true
        }
      ]
    },
    transfers () {
      return this.paginatedData.data
    }
  },
  methods: {
    getTransferStatusText (status) {
      switch (status) {
        case 'pending':
          return 'En attente de la complétion de la commande'

        case 'scheduled':
          return 'En cours de paiement'

        case 'processed':
          return 'Paiement envoyé'

        default:
          return ''
      }
    },
    async changePage (page) {
      await this.fetchTransfers(page)
    },
    async fetchTransfers (page) {
      await this.$store.dispatch('transfers/fetch', { page, storeId: this.currentStoreId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchTransfers()
    }
  },
  async created () {
    this.instance = 'transfers'
    await this.fetchTransfers()
  }
}
</script>
